
.inner-wrapper{
    padding-top: 0px;
    width: 95%;
    margin: 0 auto;
}
.ant-col-offset-1 {
    margin-left: 0% !important; 
}
.steps-content {
    margin-top: 30px;
    padding-bottom: 20px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 75vh;
    text-align: center;
    /* box-shadow: 0 0 10px rgba(0,0,0,0.5); */
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.6);
}
.location-check{
    width: 75%;
    margin: 0 auto;
    border: 3px solid #ccc;
    border-radius: 25px;
    padding: 25px;
    margin-bottom: 20px;
}
div.steps-header{
    text-align: left;
    padding: 10px;
    background-color: #1890ff;
    position: relative;
    top: 0px;
    margin-bottom: 35px;
}
div.steps-header h3{
    color: #fff;
    font-weight: bold;
    display: inline-block;
}
div.steps-header button{
    float: right;
    color: #fff;
    font-weight: 500;
}
div.steps-header button:hover, div.steps-header button:active, div.steps-header button:focus{
    color: #fff;
}
div.ant-form-item-label label{
    font-weight: bold;
    color: rgba(5, 1, 27, 0.82);
}

aside.menu-sider{
    background: url(https://nmcprofessionals.org.uk/uploads/sidebar.jpg);
    border: none;
}
.menu-sider .ant-menu{
    background: transparent;
}

.ant-layout-sider-children{
    background-color: rgba(5, 1, 27, 0.82);
}

button.skip-paypal{
    float: left;
    left: 60%;
    height: 35px;
}
.payment-methods-container{
    padding-left: 75px;
    margin-bottom: 10px;
}
.payment-methods-container .payment-methods-radio{
    display: block;
    height: 35px;
    line-height: 35px;
}
.payment-methods-container .payment-method-skip{
    display: block;
    top: 10px;
}
div.paypal-btn-container div.paypal-buttons{
    float: right;
    width: 25% !important;
}
.steps-action {
    margin-top: 24px;
    min-height: 50px;
}
div.paypal-btn-container {
    /* width: 30%; */
    /* float: right; */
    position: relative;
    right: 115px;
}
.steps-action .next-button{
    float: right;
}
.steps-action .prev-button{
    float: left;
}
.form-checkbox{
    text-align: left;
}
.header-body{
    background-color:rgba(5, 1, 27, 0.82);
    /* padding-top: 10px; */
}
.header-body div.ant-row{
    top: 10px;
}
.headers{
  background-image: url(https://nmcprofessionals.org.uk/uploads/sidebar.jpg);
}
div.ant-spin-container div.payment-container{
    text-align: left;;
}
label.radio-button{
    display: block;
    line-height: 30px;
}

.braintree-paypal.braintree-sheet {
    background: transparent;
    border: none;
}
.braintree-sheet__header{
    display: none;
}
.braintree-card.braintree-form{
    background: transparent;
    border: none;
}
.braintree-sheet__content.braintree-sheet__content--form{
    width: 50%;
}
.payment-container div.card-container  div.braintree-form__label{
    display: none;
}
.braintree-placeholder {
    display: none;
}
div.braintree-form__hosted-field{
    border-radius: 5px;
    background: #fff;
}
div.payment-container {
    min-height: 200px;
}
.form-actions span button.skip-btn{
    float: right;
    right: 20px;
}
.error-alert{
    width: 95%;
    margin: 0 auto;
    position: relative;
    top: 15px;
}
.professionals-list{
    width: 100%;
    margin: 0 auto;
}
.unpaid-container{
    opacity: 0.3;
    pointer-events: none;
}
div.professionals-list div.ant-table div.ant-table-body{
    margin: 0;
}
div.professionals-list table tbody{
    background-color: #fff;
}
div.professionals-list .ant-table-title{
    background-color: #b2aeae;
}
.payment-container .ant-form-item-label{
    text-align: left;
}
div.StripeElement input,
.StripeElement {
  display: block;
  max-width: 500px;
  padding: 7px 11px;
  font-size: 14px !important;
  border: 1px solid #d9d9d9;;
  outline: 0;
  border-radius: 4px;
  background: white;
  font-variant: tabular-nums;
}

input::placeholder {
  color: #aab7c4;
}

div.StripeElement input:focus,
.StripeElement--focus {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  transition: all 150ms ease;
  border-color: #40a9ff;
  border-right-width: 1px;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}
button.payment-button{
    padding: 0 5px 0 5px;
    margin: 0;
    position: absolute;
    bottom: 2px;
}
.payment-container .ant-card-body{
    padding: 0;
}
.payment-container .payment-logo-container{
    padding: 20px;
}
.payment-container .payment-logo-container img{
    width: 100%;
    height: 100%;
}
.payment-container .ant-list-item{
    padding: 10px 15px 10px 15px;
}
.payment-container .ant-list-item label{
    font-weight: 600;
}
.payment-container .ant-list-item span{
    font-weight: 600;
    color: #0f00005c;
}

.payment-container .ant-card-head{
    background-color: #1e1c5d;
    color: #fff;
    text-align: center;
}
.payment-container .ant-list-item.bill-row{
    background-color: #b7c4de8c;
    font-size: larger;
    font-weight: bolder;

}
.payment-container .ant-list-item.bill-row label,
.payment-container .ant-list-item.bill-row span{
    color: #fff;
}
.payment-container .message-container{
    margin: 0 auto;
    width: 72%;
    position: relative;
    right: 18px;
    margin-bottom: 25px;
}
.payment-container .bottom-messages{
    position: relative;
    top: 10px;
    font-size: 9px;
}
.net-balance .ant-list-item-meta-title,
.net-balance .ant-list-item-meta-description{
    text-align: end;
}
.payment-container .ant-alert.ant-alert-error{
    margin-bottom: 20px;
}
.paypal-container{
    width: 72%;
    margin: 0 auto;
}
.payment-container .braintree-upper-container:before{
    background-color: #fff;
}
.payment-container .braintree-sheet__content.braintree-sheet__content--button{
    padding: 0;
}
.payment-container .zoid-outlet{
    width: 330px !important;
    float: right;
    right: 15px;
}
.paypal-container{
    right: 10px;
}
.paypal-container button{
    float: right;
}
.requests-container .steps-content{
    text-align: initial;
}
.requests-container .ant-tabs-tabpane{
    padding-left: 20px;
    padding-right: 20px;
}
.requests-container div.steps-header{
    margin-bottom: 20px;
}

.requests-container .ant-table-body .status-column{
    text-transform: capitalize;
}
.requests-container .ant-table-content{
    background: #fff;
    height: 100%;
}
.requests-container .ant-table-content .ant-table-thead{
    background-color: #001529;
}
.requests-container .ant-table-content .ant-table-thead th,
.requests-container .ant-table-content .ant-table-thead th i{
    color: #fff;
}
div.payment-cycle-alert{
    width: 75%;
    margin: 0 auto;
    margin-bottom: 25px;
}
.requests-container .ant-checkbox-group{
    padding-bottom: 20px;
    /* padding-left: 14px; */
}
.requests-container .requests-checkbox{
    padding-left: 14px;
}
.action-column{
    text-align: center;
}
.ant-menu-item .ant-badge{
    padding-left: 5px;
}
.ant-badge .ant-badge-count{
    background-color: #096dd9;
}
.status-item{
    text-transform: uppercase;
}
.requests-list-container{
    width: 96%;
    margin: 0 auto;
}
.requests-list-container td.action-column button{
    margin-right: 5px;
}
@media only screen and (max-width: 600px){
    .inner-wrapper{
        width: 100%;
    }
    .inner-wrapper .steps-content{
        margin: 0;
    }
    div.payment-cycle-alert{
        width: 100%;
    }
    .payment-container .message-container{
        width: 100%;
        right: 0;
    }
    .payment-methods-container{
        padding-left: 0;
    }
}