body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-field Input{
    border: 1px solid rgba(0, 0, 0, 0.21);
    padding: 7px;
    width: 67%;
    border-radius: 4px;
    box-shadow: none;
}
button.ant-button > i.anticon{
    padding-right: 5px;
}
/* Ant Tooltip */
.ant-tooltip{
    z-index: 10000 !important;
}
/* Table Title */
div.table-title{
    height: 35px;
}
div.table-title h2{
    color: #fff;
    width: 20%;
    float: left;
}
div.table-title .select-filter{
    width: 35%;
    float: left;
}
div.table-title .ant-input-search{
    display: block;
}
.ant-input-search .ant-input{
    /* width: 30%; */
    float: right;
}
.search-input.ant-input-search .ant-input-suffix{
    margin-top: 15px;
}
.ant-list-item-meta-title span p{
    font-weight: 400;
    color: #b0a3a3;
}
div.checkbox-container,div.datepicker-container div.ant-form-item-control{
    text-align: left;
}
div.ant-form-extra{
    text-align: left;
}
span.ant-time-picker{
    width: 100%;
    margin-top: 10px;
}
.switch-post-label{
    font-weight: 500;
    padding-left: 20px;
}
div.text-area div.ant-form-item-label{
    text-align: left;
}
.image-input div.ant-upload-select-picture-card{
    border: none;
    border-radius: 50%;
    background-color: #bebaba;
}
.image-input div.ant-upload-list-item-list-type-picture-card{
    padding: 0;
    border: none;
    width: 140px;
    height: 140px;
}
.image-input div.ant-upload-list-item-list-type-picture-card .ant-upload-list-item-info{
    border-radius: 50%;
    width: 140px;
    height: 140px;
}
.image-input .ant-form-item-label{
    min-height: 100px;
}
.image-input label{
    top: 50px;
}
.image-input .ant-upload.ant-upload-select-picture-card{
    width: 140px;
    height: 140px;
}
.image-input .ant-upload-list-picture-card-container{
    width: 140px;
    height: 140px;
}
.image-input i{
    font-size: 38px;
    color: #fff;
}
.image-input .ant-upload-select-picture-card:hover{
    background-color: #000;
    opacity: 0.7;
}
.image-input .ant-upload.ant-upload-select-picture-card .ant-upload{
    padding: 0;
}

/** Modal Box **/
div.ant-modal-root div.ant-modal-content div.ant-modal-header{
    background-color: #1890ff;
}
div.ant-modal-root div.ant-modal-content div.ant-modal-header .ant-modal-title,
div.ant-modal-root div.ant-modal-content .ant-modal-close-x{
    color: #fff;
}
div.ant-modal-root div.ant-modal-content div.ant-modal-header .ant-modal-title i{
    padding-right: 5px;
}
/** Field Erros  **/
.field-error{
    color: red;
}
/* div.ant-modal-content, div.ant-modal-header{
    border-radius: 0px;
}
div.ant-modal-header{
    padding: 10px;
    background-color: #1e1c5d !important;
}
div.ant-modal-title{
    font-size: 14px !important;
    font-weight: 700;
}
button span.ant-modal-close-x{
    height: 42px;
    line-height: 40px;
}
div.ant-modal-content div.ant-modal-footer{
    padding: 10px 16px;
    background-color: #d8d8e19e;
}
div.ant-modal-footer button{
    border-radius: 0;
    height: 26px;
}
div.ant-modal-content div.ant-modal-body{
    background-color: #fff;
} */

/** Receipt Card **/

.receipt-card .ant-card-head{
    border-bottom: 5px solid #1890ff;
    color: #1890ff;
}
.receipt-card .ant-card-head .receipt-subtitle{
    display: block;
    position: relative;
    left: 6px;
    font-weight: 600;
    font-size: 13px;
    color: #a19a9a;
}

/* .receipt-card .ant-card-head-title, */
.receipt-card .ant-card-extra{
    padding: 0;
}

.receipt-card .data-container{
    position: relative;
    top: 20px;
    width: 95%;
    margin: 0 auto;
}
.receipt-card .data-container label{
    font-size: 14px;
    font-weight: 500;
    color: #1890ff;
}
.addform-container{
  min-height: 100vh;
  padding: 30px 20px 0 20px;
  background-color: #dbdced;
}

div.addform-container div.ant-row.addform-panel{
  background-color: #1e1c5d;
  min-height: 83vh;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.div.ant-row.addform-panel{
  width: 88%;
  margin: 0 auto;
}

.addform-container .progress-tail{
  text-align: center;
  border: 10px solid #fff;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border-radius: 50%;
  display: table;
  position: relative;
  top: 20vh;
}

.addform-container .form-panel{
  background-color: white;
  height: 83vh;
  
}

.panel-btns{
  margin-bottom: 30px;
}

.addform-container .form-icon{
  font-size: 100px;
  display: table-cell;
  vertical-align: middle;
}

.addform-container .progress-panel i.anticon{
  color: #fff;
}

/* .form-panel .tabs-content{
  padding: 0 0 0 38px;
} */

.form-panel .panel-btns{
  width: 82%;
}
.addform-panel .form-alert{
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
}
.form-panel .tab-btns{
  width: 68%;
  margin: 0 auto;
  margin-bottom: 100px;
}

.ant-message{
  display: none;
}

.form-panel{
  overflow-y: scroll;
}
.form-panel::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
background-color: #F5F5F5;
border-radius: 10px;
}

.form-panel::-webkit-scrollbar
{
width: 10px;
background-color: #F5F5F5;
}

.form-panel::-webkit-scrollbar-thumb
{
border-radius: 10px;
background-image: -webkit-gradient(linear,
           left bottom,
           left top,
           color-stop(0.44, rgb(122,153,217)),
           color-stop(0.72, rgb(73,125,189)),
           color-stop(0.86, rgb(28,58,148)));
}

@media only screen and (max-width: 800px){
  .addform-container{
    padding: 0;
  }
  .form-panel .ant-tabs-nav-wrap{
    text-align: center;
  }
  .form-panel .ant-tabs-nav-wrap .ant-tabs-nav .ant-tabs-tab-active{
    border-bottom: 2px solid #fff;
  }
  .form-panel .ant-tabs-ink-bar{
    background-color: #1e1c5d;
  }
  .form-panel .tab-btns{
    width: 75%;
    margin: 0 auto;
  }
  .form-panel .ant-tabs-nav-wrap .ant-tabs-nav .ant-tabs-tab-active,
  .form-panel .ant-tabs-nav-container,
  .form-panel .ant-card-head{
    background-color: #1890ff;
    color: #fff;
  }
}

@media only screen and (max-width: 600px){
  .form-panel .panel-btns{
    width: 93.4%;
    margin-bottom: 20px;
  }
  .form-panel .tabs-content{
    width: 90%;
    margin: 0 auto;
  }
  
  .form-panel .tab-btns{
    width: 90%;
    margin: 0 auto;
    margin-bottom: 75px;
  }
  .addform-panel .form-alert{
    width: 100%;
  }
  .ant-tabs-tab-arrow-show i{
    color: #fff;
  }
  .form-section-div{
    width: 95%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 380px){
  
  .form-panel .panel-btns{
    width: 92%;
    margin-bottom: 60px;
  }
}
.main-container{
  display: table;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.main-container .gradient-layer{
  position: relative;
  background-color: rgba(17, 10, 52, 0.8);
  background-size: cover;
  height: 100vh;
}

.main-container .gradient-layer .header {
  display: block;
  width: 100%;
}

.main-container .gradient-layer .header .logo{
  padding: 60px;
  width: 20%;
}

.main-container .gradient-layer .header .logo img{
  width: 100%;
  height: 100%;
}

.main-container .gradient-layer .data-container{
  position: absolute;
  height: 100vh;
  width: 100%;
  float: left;
}
.main-container .gradient-layer .data-container .text-container,
.main-container .gradient-layer .data-container .form-container{
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.full-row-btn{
  margin: 10px;
}
.main-container .gradient-layer .data-container .text-container .welcome-text{
  width: 60%;
  padding-left: 5%;
}
.main-container .gradient-layer .data-container .text-container .welcome-text h1.mobile{
  display: none;
}
.main-container .gradient-layer .data-container .text-container h1,
.main-container .gradient-layer .data-container .text-container p{
  color: #fff;
}

.main-container .gradient-layer .form-container .form-content{
  margin: 0 auto;
  width: 80%;
  background-color: rgba(7, 8, 30, 0.52);
}

.main-container .gradient-layer .form-container .form-content .form{
  width: 90%;
  margin: 0 auto;
}

.main-container .gradient-layer .form-container .form-content .form .form-title{
  padding: 20px 0 5px 0;
  font-family: inherit;
  font-weight: normal;
  font-size: 22px;
  color: #fff;
}

.ant-radio-group .ant-radio-button-wrapper{
  background: transparent;
}

.main-container .gradient-layer .form-container .form-content .form .form-fields .ant-radio-group.ant-radio-group-solid{
  width: 100%;
  padding: 0 0 20px 0;
}

.main-container .gradient-layer .form-container .form .ant-radio-group .ant-radio-button-wrapper{
  text-align: center;
  width: 50%;
  color: #fff;
}

.main-container .gradient-layer .form-container .form-content .form input{
  height: 40px;
  border: none;
  border-radius: 0;
  outline: none;
  border-bottom: 1px solid #fff;
  background-color: transparent;
  box-shadow: none;
  color: #fff;
}

.terms-drawer .ant-drawer-close,
.contact-drawer .ant-drawer-close{
    color: #fff;
}

.login-form span.ant-input-password input.ant-input{
  padding-left: 10px;
}

@media only screen and (min-width: 1900px){
  .main-container .gradient-layer .form-container .form-content{
    width: 60%;
  }
}

@media only screen and (max-width: 1200px){
  .main-container .gradient-layer .form-container .form-content{
    width: 60%;
  }
  .main-container .gradient-layer .data-container .text-container .welcome-text{
    width: 100%;
  }
}

@media only screen and (max-width: 992px){
  .gradient-layer{
    overflow-y: auto;
  }
  .main-container .gradient-layer .header .logo{
    width: 40%;
  }
  .main-container .gradient-layer .data-container{
    position: relative;
  }
  .main-container .gradient-layer .data-container .text-container,
  .main-container .gradient-layer .data-container .form-container{
    position: relative;
    top: 0;
    -webkit-transform: unset;
            transform: unset;
  }

  .main-container .gradient-layer .data-container{
    margin-bottom: 200px;
  }

  .main-container .gradient-layer .data-container .text-container .welcome-text{
    padding: 10px 10px 20px 20px;
    margin: 0 auto;
    background-color: #fff;
    opacity: 0.5;
  }
  .main-container .gradient-layer .data-container .text-container .welcome-text h1,
  .main-container .gradient-layer .data-container .text-container .welcome-text p{
    color: #000;
  }
  .main-container .gradient-layer .data-container .text-container .welcome-text,
  .main-container .gradient-layer .data-container .form-container .form-content{
    width: 85%;
  }
  .ant-drawer-content-wrapper{
    width: 75% !important;
  }
}

@media only screen and (max-width: 600px){
  .main-container .gradient-layer .data-container .text-container .welcome-text,
  .main-container .gradient-layer .data-container .form-container .form-content{
    width: 95%;
  }
  .main-container .gradient-layer .header .logo{
    width: 60%;
    padding: 20px 0 20px 0;
    margin: 0 auto;
  }
  .main-container .gradient-layer .data-container{
    margin-bottom: 50px;
  }
  .ant-drawer-content-wrapper{
    width: 100% !important;
  }
  .ant-message{
    display: block;
    /* position: fixed !important; */
    /* bottom: 24px; */
  }

  .ant-message .ant-message-notice-content{
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
  }

  .ant-message .ant-message-notice-content i{
    /* display: none; */
    color: #fff;
  }

  .ant-notification{
    display: none;
  }
}

@media only screen and (max-width: 480px){
  .main-container .gradient-layer .data-container .text-container .welcome-text h1.full-screen{
    display: none;
  }
  .main-container .gradient-layer .data-container .text-container .welcome-text h1.mobile{
    display: block;
  } 
}
div.steps-content.search-content{
  background-color: #fff;
}
.professionals-list.ant-row{
  padding: 20px;
}
.professionals-list .ant-card{
  width: 95%;
  margin: 0 auto;
  /* box-shadow: 0 0 10px rgba(0,0,0,0.5); */
  margin-bottom: 30px;
  text-align: left;
}

.professionals-list .ant-card .ant-card-head,
.professionals-list .ant-card .ant-card-extra{
  background-color: #1890ff;
  color: #fff;
}

.professionals-list .ant-card .ant-list-item label {
  font-weight: 500;
  font-size: 14px;
  color: #000;
}
.professionals-list .ant-card .ant-list-item label i{
  padding-right: 5px;
}

.professionals-list .ant-card .ant-list-item span {
  position: absolute;
  right: 0;
  font-weight: 500;
  opacity: 0.8;
}
.professionals-list .ant-card .ant-card-actions i{
  font-size: 22px;
}
.field-error{
  color: red;
}
.search-container .ant-form-item-control-wrapper{
  text-align: left;
}
.search-form{
  margin-bottom: 50px;
}
.search-form-container{
  text-align: left;
}
.search-form-container .process-steps{
  width: 95%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.search-container .ant-radio-button-wrapper{
  height: auto;
  padding: 5px 30px 0px 30px;
}
.search-container .ant-radio-button-wrapper-checked span h4,
.search-container .ant-radio-button-wrapper-checked span h5{
  color: #fff;
}
.search-container .shifts-row .ant-divider-horizontal{
  margin: 5px 0;
}
.search-container .date-header{
  border: 1px solid #ffffff;
  background-color: #f1f1f1;
  width: 95%;
  margin: 0 auto;
  padding: 7px;
  padding-left: 20px;
}

.search-container .date-header .ant-page-header-heading-title,
.search-container .date-header .ant-page-header-heading-extra{
  color: #333;
}
.search-container .date-header .ant-page-header-heading-sub-title{
  color: #a1a0a0;
}
.search-container .empty-list{
  padding: 20px;
}
.search-container .date-header .ant-page-header-heading-extra{
  padding-top: 3px;
  padding-right: 25px;
}
.search-container .shift-cell,
.search-container .shift-name,
.search-container .shift-date{
  display: inline-block;
  padding: 10px;
  text-align: center;
  /* font-weight: 700; */
  border: 1px solid #68606012;
}
.search-container .shift-cell{
  width: 120px;
  padding: 0;
}
.search-container .shift-cell .ant-form-item-control-wrapper{
  text-align: center;
}
.search-container .shift-cell .checkbox-container  .ant-form-item{
  margin: 0;
}
.search-container .shift-name{
  width: 175px;
  color: #fff;
  background-color: #1890ff;
}
.search-container .empty-shift-cell{
  display: inline-table;
  background-color: #1890ff;
  width: 175px;
}
.search-container .shift-date{
  width: 120px;
  background-color: #1890ff;
  color: #fff;
}
.search-container .search-btn button{
  float: right;
  margin-left: 10px;
  right: 95px;
  top: 20px;
}
.professionals-list .ant-card .ant-list-item .ant-btn-primary.ant-btn-block{
  height: 50px;
}
.professionals-list .ant-card .card-title-extra{
  font-size: 12px;
  margin-left: 20px;
  color: rgba(255, 255, 255, 0.815);
}
.professionals-list .ant-card .ant-list-item .ant-btn-primary.ant-btn-block span{
  position: relative;
}
.offer-modal{
  top: 20px;
}

.offer-form-container .ant-checkbox-group-item.ant-checkbox-wrapper{
  min-width: 45%;
  line-height: 3em;
}
.price-field{
  width: 20%;
}

.shifts-row .ant-checkbox-inner{
  border: 2px solid #1e1c5d;
}
div.search-mobile-message{
  display: none;
}

@media only screen and (max-width: 1020px){
  div.search-mobile-message{
    display: block;
    width: 90%;
    margin: 0 auto;
  }
  .search-mobile-form{
    display: none;
  }
}
.profile-view{
    text-align: left;
}
.profile-view .ant-card{
    width: 95%;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    margin-bottom: 30px;
}
.profile-view .profile-details .text-label{
    padding-left: 0px;
    padding-top: 10px;
}
.profile-view .profile-details .ant-col h1{
    font-size: 22px;
    margin-bottom: 0px;
}
.profile-view .profile-details .ant-col h1 span.ant-tag{
    background-color: #449d44;
    border-color: #b7eb8f;
    color: #fff;
}
.profile-view .profile-details .text-label span{
    font-weight: 500;
    font-size: 14px;
    color: #806b6b;
}

.profile-view i.anticon{
    padding-right: 5px;
    font-size: 20px;
}
.profile-view .profile-list .ant-list-item label{
    font-weight: 500;
    font-size: 14px;
    color: #000;
}

.profile-view .profile-list .ant-list-item span.label-value{
    position: absolute;
    display: contents;
    right: 0;
    font-weight: 500;
    opacity: 0.8;
}
.profile-view .ant-avatar{
    margin: 0 auto;
}
.profile-view .ant-avatar i.anticon{
    font-size: 80px;
    padding-right: 0;
    
}
.document-viewer .pdf-canvas canvas{
    width: 100%;
}
.document-viewer .pg-viewer-wrapper{
    overflow-y: hidden;
}
div.modal-image.professionals-card{
    width: 100%;
    height: auto;
    overflow: hidden;
}
.image-avatar{
    cursor: pointer;
}
.pro-card .ant-card{
    width: 97%;
}
div.pg-viewer-wrapper{
    overflow-y: hidden;
}
.document-modal .ant-modal-close-icon{
    color: #000;
}
div.photo-viewer-container{
    width: -webkit-fit-content !important;
    width: fit-content !important;
    height: 80vh !important;
    overflow: hidden;
}
.photo-viewer-container img{
    /* position: absolute !important; */
    width: auto !important;
    height: 100% !important;
}
.header-body .logo{
  float: left;
  height: 60px;
  background-size: 150px 50px;
  background-repeat: no-repeat;
  width: 80%;
}

.profile-logo{
  margin-left: 5%;
  position: relative;
  bottom: 5px;
}

.header-body button{
  position: relative;
  top: 3px;
  right: 80px;
}

@media only screen and (max-width: 600px){
  .header-body button{
    right: 10px;
  }
  .header-body button span{
    display: none;
  }
  .header-body button i.anticon{
    padding-right: 0;
  }
  .header-body .logo.hide{
    background-image: unset;
  }
  .ant-layout-sider-zero-width-trigger:hover{
    background: none !important;
  }
  .ant-layout-sider-zero-width-trigger{
    margin-top: -10px;
    margin-left: 10px;
    border-radius: 5px;
  }
}

.inner-wrapper{
    padding-top: 0px;
    width: 95%;
    margin: 0 auto;
}
.ant-col-offset-1 {
    margin-left: 0% !important; 
}
.steps-content {
    margin-top: 30px;
    padding-bottom: 20px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 75vh;
    text-align: center;
    /* box-shadow: 0 0 10px rgba(0,0,0,0.5); */
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.6);
}
.location-check{
    width: 75%;
    margin: 0 auto;
    border: 3px solid #ccc;
    border-radius: 25px;
    padding: 25px;
    margin-bottom: 20px;
}
div.steps-header{
    text-align: left;
    padding: 10px;
    background-color: #1890ff;
    position: relative;
    top: 0px;
    margin-bottom: 35px;
}
div.steps-header h3{
    color: #fff;
    font-weight: bold;
    display: inline-block;
}
div.steps-header button{
    float: right;
    color: #fff;
    font-weight: 500;
}
div.steps-header button:hover, div.steps-header button:active, div.steps-header button:focus{
    color: #fff;
}
div.ant-form-item-label label{
    font-weight: bold;
    color: rgba(5, 1, 27, 0.82);
}

aside.menu-sider{
    background: url(https://nmcprofessionals.org.uk/uploads/sidebar.jpg);
    border: none;
}
.menu-sider .ant-menu{
    background: transparent;
}

.ant-layout-sider-children{
    background-color: rgba(5, 1, 27, 0.82);
}

button.skip-paypal{
    float: left;
    left: 60%;
    height: 35px;
}
.payment-methods-container{
    padding-left: 75px;
    margin-bottom: 10px;
}
.payment-methods-container .payment-methods-radio{
    display: block;
    height: 35px;
    line-height: 35px;
}
.payment-methods-container .payment-method-skip{
    display: block;
    top: 10px;
}
div.paypal-btn-container div.paypal-buttons{
    float: right;
    width: 25% !important;
}
.steps-action {
    margin-top: 24px;
    min-height: 50px;
}
div.paypal-btn-container {
    /* width: 30%; */
    /* float: right; */
    position: relative;
    right: 115px;
}
.steps-action .next-button{
    float: right;
}
.steps-action .prev-button{
    float: left;
}
.form-checkbox{
    text-align: left;
}
.header-body{
    background-color:rgba(5, 1, 27, 0.82);
    /* padding-top: 10px; */
}
.header-body div.ant-row{
    top: 10px;
}
.headers{
  background-image: url(https://nmcprofessionals.org.uk/uploads/sidebar.jpg);
}
div.ant-spin-container div.payment-container{
    text-align: left;;
}
label.radio-button{
    display: block;
    line-height: 30px;
}

.braintree-paypal.braintree-sheet {
    background: transparent;
    border: none;
}
.braintree-sheet__header{
    display: none;
}
.braintree-card.braintree-form{
    background: transparent;
    border: none;
}
.braintree-sheet__content.braintree-sheet__content--form{
    width: 50%;
}
.payment-container div.card-container  div.braintree-form__label{
    display: none;
}
.braintree-placeholder {
    display: none;
}
div.braintree-form__hosted-field{
    border-radius: 5px;
    background: #fff;
}
div.payment-container {
    min-height: 200px;
}
.form-actions span button.skip-btn{
    float: right;
    right: 20px;
}
.error-alert{
    width: 95%;
    margin: 0 auto;
    position: relative;
    top: 15px;
}
.professionals-list{
    width: 100%;
    margin: 0 auto;
}
.unpaid-container{
    opacity: 0.3;
    pointer-events: none;
}
div.professionals-list div.ant-table div.ant-table-body{
    margin: 0;
}
div.professionals-list table tbody{
    background-color: #fff;
}
div.professionals-list .ant-table-title{
    background-color: #b2aeae;
}
.payment-container .ant-form-item-label{
    text-align: left;
}
div.StripeElement input,
.StripeElement {
  display: block;
  max-width: 500px;
  padding: 7px 11px;
  font-size: 14px !important;
  border: 1px solid #d9d9d9;;
  outline: 0;
  border-radius: 4px;
  background: white;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant: tabular-nums;
}

input::-webkit-input-placeholder {
  color: #aab7c4;
}

input::placeholder {
  color: #aab7c4;
}

div.StripeElement input:focus,
.StripeElement--focus {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  transition: all 150ms ease;
  border-color: #40a9ff;
  border-right-width: 1px;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}
button.payment-button{
    padding: 0 5px 0 5px;
    margin: 0;
    position: absolute;
    bottom: 2px;
}
.payment-container .ant-card-body{
    padding: 0;
}
.payment-container .payment-logo-container{
    padding: 20px;
}
.payment-container .payment-logo-container img{
    width: 100%;
    height: 100%;
}
.payment-container .ant-list-item{
    padding: 10px 15px 10px 15px;
}
.payment-container .ant-list-item label{
    font-weight: 600;
}
.payment-container .ant-list-item span{
    font-weight: 600;
    color: #0f00005c;
}

.payment-container .ant-card-head{
    background-color: #1e1c5d;
    color: #fff;
    text-align: center;
}
.payment-container .ant-list-item.bill-row{
    background-color: #b7c4de8c;
    font-size: larger;
    font-weight: bolder;

}
.payment-container .ant-list-item.bill-row label,
.payment-container .ant-list-item.bill-row span{
    color: #fff;
}
.payment-container .message-container{
    margin: 0 auto;
    width: 72%;
    position: relative;
    right: 18px;
    margin-bottom: 25px;
}
.payment-container .bottom-messages{
    position: relative;
    top: 10px;
    font-size: 9px;
}
.net-balance .ant-list-item-meta-title,
.net-balance .ant-list-item-meta-description{
    text-align: end;
}
.payment-container .ant-alert.ant-alert-error{
    margin-bottom: 20px;
}
.paypal-container{
    width: 72%;
    margin: 0 auto;
}
.payment-container .braintree-upper-container:before{
    background-color: #fff;
}
.payment-container .braintree-sheet__content.braintree-sheet__content--button{
    padding: 0;
}
.payment-container .zoid-outlet{
    width: 330px !important;
    float: right;
    right: 15px;
}
.paypal-container{
    right: 10px;
}
.paypal-container button{
    float: right;
}
.requests-container .steps-content{
    text-align: left;
    text-align: initial;
}
.requests-container .ant-tabs-tabpane{
    padding-left: 20px;
    padding-right: 20px;
}
.requests-container div.steps-header{
    margin-bottom: 20px;
}

.requests-container .ant-table-body .status-column{
    text-transform: capitalize;
}
.requests-container .ant-table-content{
    background: #fff;
    height: 100%;
}
.requests-container .ant-table-content .ant-table-thead{
    background-color: #001529;
}
.requests-container .ant-table-content .ant-table-thead th,
.requests-container .ant-table-content .ant-table-thead th i{
    color: #fff;
}
div.payment-cycle-alert{
    width: 75%;
    margin: 0 auto;
    margin-bottom: 25px;
}
.requests-container .ant-checkbox-group{
    padding-bottom: 20px;
    /* padding-left: 14px; */
}
.requests-container .requests-checkbox{
    padding-left: 14px;
}
.action-column{
    text-align: center;
}
.ant-menu-item .ant-badge{
    padding-left: 5px;
}
.ant-badge .ant-badge-count{
    background-color: #096dd9;
}
.status-item{
    text-transform: uppercase;
}
.requests-list-container{
    width: 96%;
    margin: 0 auto;
}
.requests-list-container td.action-column button{
    margin-right: 5px;
}
@media only screen and (max-width: 600px){
    .inner-wrapper{
        width: 100%;
    }
    .inner-wrapper .steps-content{
        margin: 0;
    }
    div.payment-cycle-alert{
        width: 100%;
    }
    .payment-container .message-container{
        width: 100%;
        right: 0;
    }
    .payment-methods-container{
        padding-left: 0;
    }
}


body{
    overflow: hidden;
    background:#f0f2f5;
}
.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger{
    background: none;
}
.ant-layout-sider-zero-width-trigger:hover {
    background: #192c3e00;
}
.ant-layout-sider-zero-width-trigger {
    position: absolute;
    top: -54px !important;
    font-size: 24px;
    left: 0; }



.weekly-row{
    padding-left: 20px;
    padding-right: 20px;
}
.weekly-row h3{
    text-align: left;
}
.weekly-row .ant-card-head{
    min-height: 0px;
    padding: 0;
    height: 35px;
    font-size: 15px;
}
.weekly-row .ant-card-head-wrapper{
    height: inherit;
}
.weekly-row .ant-card-head-title{
    background-color: #1e1c5d;
    color: #fff;
    height: inherit;
    padding: 5px;
}
.weekly-row .ant-card-body{
    padding: 0;
}
.weekly-row .ant-card-body button.add-schedule-button{
    width: 100%;
    height: 90px;
    padding: 0;
    border: 0;
    background-color: #fff;
    border-radius: 0;
    color: #0000007a;
    font-size: 40px;
}
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #248eef; 
    border-radius: 5px;
  }
  
.inner-body-wrapper{
    overflow-y: scroll;
    height: 90vh;    
}
.inner-body-wrapper main{
    position: relative;
    min-height: auto;
}
.ant-menu-item .ant-menu-item-selected {
    Margin-top: 0px;}

div.timesheet-drawer{
    position: absolute;
}

div.timesheet-drawer button.select-button{
    margin-top: 10px;
    border-radius: 0;
    min-height: 60px;
    height: auto;
}
div.timesheet-drawer .submit-button{
    margin-top: 15px;
}

div.timesheet-drawer button.select-button h4{
    margin-top: 10px;
}

.new-timesheet-btn i{
    padding-right: 10px;
    border-right: 1px solid #fff;
}

.ant-drawer-body p{
    text-align: left;
}

div.steps-content{
    border: none;
    position: relative;
    overflow: hidden;
    border-radius: 0;
}
div.ant-drawer-header{
    background-color: #1e1c5d;
    border-radius: 0;
}

div.ant-drawer-header div.ant-drawer-title{
    color: #fff;
}
.ant-card-body button.shift-add-button.add-schedule-button{
    background-color: #74a274fc;
}
.selected-time{
    position: relative;
    top: 5px;
    font-size: 16px;
}
.selected-time h5{
    color: #fff;
}

button.save-schedule-button {
    width: 100%;
    height: 125px;
    border: 0;
    border-radius: 0;
    font-size: 18px;
}
button.save-schedule-button-mob {
    width: 100%;
    margin-top: 6px;
    height: 54px;
    border: 0;
    border-radius: 0;
    box-shadow: 0 0 8px rgba(0,0,0,0.5);
    font-size: 18px;
}
button.skip-button,span.skip-button{
    float: left;
    left: 10px;
}
button.prev-button,span.prev-button{
    float: right;
    right: 15px;
    margin-left: 2px;
}
.timesheet-list-add{
    display: -webkit-flex;
    display: flex;
    color: #fff;
    box-shadow: 0 0 8px rgba(0,0,0,0.5);
    background-color: #1890ff;
    margin: 7px;
}
button.timesheet-btn{
    padding: 0px;
    border-radius: 0;
    color: #0000007a;
    font-size: 30px;
    height: 54px;
    border: 0px solid transparent;
}
button.shift-add-buttonlist{
    color: #0000007a;
    background-color: #74a274fc;
    border-radius: 0;
    height: 54px;
}
p.timesheet-list-add-p{
    padding-top: 9px;
    padding-left: 10px;
    font-size: 18px;
    display: -webkit-flex;
    display: flex;
}
.ant-layout-sider {
    /* position: absolute ; */
    z-index: +1;
    height: 97vh;}
  

.ant-layout-sider-zero-width-trigger {
    position: absolute;
    top: -43px !important;
    font-size: 27px !important;
    left: 0; }

button.shift-add-buttonlist .selected-time{
    display: block;
}
button.skip-button i {
    font-weight: 500;
}
.weekly-row .ant-card{
    border: none;;
    /* box-shadow: 0 0 10px rgba(0,0,0,0.5); */
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.6);
}
.timesheet-card{
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.6);
    position: relative;
    top: 18px;
}

.timesheet-card .ant-card-head{
    padding: 0;
    background-color: #1e1c5d;
    color: #fff;
}
.timesheet-card .ant-list-items{
    text-align: left;
    color: #1890ff;
}
.timesheet-card .ant-card-head-title{
    text-align: left;
    padding-left: 20px;
}
.timesheet-card .ant-card-extra{
    padding-right: 20px;
    color: #fff;
    font-size: 18px;
}
.timesheets-row{
    padding: 0 50px 50px 50px;
    text-align: left;
}
.timesheets-row .timesheet{
    padding: 20px;
}
.weekly-row .ant-col{
    margin-left: 15px;
    padding-bottom: 10px;
}
.weekly-row .ant-col .ant-btn-group{
    float: right;
    right: 10px;
}
button.ant-btn-primary h4, button.ant-btn-primary h5{
    color: #fff;
}
.schedule-action-btns{
    position: relative;
    top: 15px;
}
.non-submit-btns{
    float: left;
    position: relative;
    left: 40px;
}
.non-submit-btns button{
    margin-right: 10px;
}
.submit-btn{
    float: right;
    position: relative;
    right: 45px;
}
p.error-message{
    color: #ed5959;
}
.edit-shift button.select-button{
    min-height: 65px;
    margin-top: 10px;
    padding: 5px;
}
button.ant-btn.add-schedule-button[disabled]{
    background-color: #e5d9d9;
}
button.timesheetbtn{
    float: right;
    width: 60px;
    box-shadow: 0 0 8px rgba(0,0,0,0.5);
    height: 60px;
    font-size: 37px;
}
.timesheet-panel div.ant-collapse-header{
    padding: 0;
    height: 50px;
    background-color: #1e1c5d;
    color: #fff !important;
    font-size: 14px;
    font-weight: 500;
}
.single-timesheet-switch{
    width: 170px;
    margin: 0;
    padding: 0;
    position: relative;
    right: 20px;
}
.timesheet-available{
    font-size: '13px';
    color:rgba(0, 0, 0, 0.45);
}
.single-timesheet-card-shift{
    text-align: center;
    padding: 0;
    width: 120px;
    margin: 0;
    position: relative;
    right: 50px;
}
.timesheet-panel div.ant-collapse-content-box{
    padding: 0;
}
.timesheet-indicator{
    font-weight: 500;
    font-size: 14px;
    box-shadow: 0 0 8px rgba(0,0,0,0.5);
}
.timesheet-indicator .title{
    background-color: #248eef;
    height: 100%;
    padding: 18px;
    color: #fff;
}
.timesheet-indicator .navigator{
    background-color: #fff;
    height: 100%;
    padding: 10px;
    height: inherit;
    float: right;
    text-align: center;
}
.timesheet-indicator .navigator button{
    font-size: 25px;
}
@media only screen and (max-width: 1024px) {
    .ant-layout-sider {
        position: absolute !important;}
}

@media only screen and (max-width: 800px) {
    
    .timesheets-row .timesheet {
        padding: 7px;
        padding-bottom: 20px;
    }
    .single-timesheet-switch{
        width: 130px;
        margin-left: 0px;
        right: 150px;
    }

    .timesheets-row {
        padding: 0 0px 50px 2px;
        text-align: left;
    }
    .ant-list-item-meta-title {
        width: 93px;
    }
    .timesheet-indicator .title {
        padding: 12px;
    }

    .timesheet-indicator .navigator {
        padding: 5px;
    }

    .timesheet-card-h4{
        float: right;
        margin-left: 8px;
    }
    .single-timesheet-card-shift{
        text-align: left;
        padding-right: 0px;
    }
    .timesheet-available{
        display: none;
    }
    button.shift-add-buttonlist .selected-time {
        width: 50px;
        -webkit-align-content: center;
                align-content: center;
        margin: 0px auto;
        display: inherit;
    }
    .selected-time h5{
        line-height: 1;
        margin: 2px;
        padding: 0;
        width: 50px;
        font-size: 11px;
    }
}

@media only screen and (max-width: 600px){
    .weekly-row{
        padding: 0;
    }
    .weekly-row .ant-col{
        margin: 0;
    }
    .non-submit-btns{
        position: absolute;
        /* bottom: 65px; */
        top: -95px;
        width: 100%;
        text-align: center;
        /* margin: 0; */
        float: unset;
        left: unset;
    }
    .submit-btn{
        float: right;
        right: 10px;
        margin-bottom: 30px;
        /* float: left; */
    }
    .timesheet-card .ant-card-body{
        padding: 5px;
    }
    .timesheet-card .single-timesheet-switch{
        width: 80px;
        right: 10px;
    }
    .timesheet-card-h4{
        float: left;
    }
    .single-timesheet-card-shift{
        width: 100px;
        right: 15px;
    }
}

@media only screen and (max-width: 340px){
    .timesheet-card .single-timesheet-card-shift{
        right: 0;
    }
    .timesheet-card .single-timesheet-switch{
        width: 130px;
    }
    .timesheet-card-h4{
        float: right;
        margin-left: 8px;
    }
}
.profile-view{
    text-align: left;
}
.profile-view .ant-card{
    width: 95%;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    margin-bottom: 30px;
}
.profile-view .profile-details .text-label{
    padding-left: 0px;
    padding-top: 10px;
}
.profile-view .profile-details .ant-col h1{
    font-size: 22px;
    margin-bottom: 0px;
}

.password-form input.ant-input{
    padding-left: 10px !important;
}
.profile-view .profile-details .ant-col h1 span.ant-tag{
    background-color: #449d44;
    border-color: #b7eb8f;
    color: #fff;
}
.profile-view .profile-details .text-label span{
    font-weight: 500;
    font-size: 14px;
    color: #806b6b;
}

.profile-view i.anticon{
    padding-right: 5px;
    font-size: 20px;
}
.profile-view .profile-list .ant-list-item label{
    font-weight: 500;
    font-size: 14px;
    color: #000;
}

.content-submit-btn{
    float: right;
    right: 211px;
}

.profile-view .profile-list .ant-list-item span.label-value{
    position: absolute;
    display: contents;
    right: 0;
    font-weight: 500;
    opacity: 0.8;
}
.profile-view .ant-avatar{
    margin: 0 auto;
}
.card-footer-btn{
    margin-bottom: 10px;
}
.profile-view .ant-avatar i.anticon{
    font-size: 80px;
    padding-right: 0;
    
}
.document-viewer .pdf-canvas canvas{
    width: 100%;
}
.document-viewer .pg-viewer-wrapper{
    overflow-y: hidden;
}
div.modal-image{
    width: 100%;
    height: auto;
    overflow: hidden;
}
.image-avatar{
    cursor: pointer;
}
.pro-card .ant-card{
    width: 97%;
}
div.pg-viewer-wrapper{
    overflow-y: hidden;
}
.document-modal .ant-modal-close-icon{
    color: #000;
}
div.photo-viewer-container{
    width: -webkit-fit-content !important;
    width: fit-content !important;
    height: 80vh !important;
    overflow: hidden;
}
.photo-viewer-container img{
    /* position: absolute !important; */
    width: auto !important;
    height: 100% !important;
}

@media only screen and (max-width: 600px){
    .content-submit-btn{
        right: 10px;
    }
    .profile-list .ant-tag{
        display: none;
    }
}
/* div.requests-container div.offers-container div.list-content{
  height: 450px;
  border-right: 2px solid #eee7e7;
  overflow-y: auto;
  padding-left: 20px !important;
} */
.offers-list-container .ant-card-body{
  padding: 0;
}
div.requests-container div.list-content{
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-height: 550px;
  border-right: 1px solid #eee7ee;
}
div.requests-container div.list-content li.ant-list-item{
  border-radius: 0;
  padding-left: 30px;
}
div.requests-container div.list-content .ant-list-item-meta-description{
  font-weight: 400;
  padding-left: 28px;
}
.offer-content{
  height: 550px;
  overflow-y: auto;
}
.active-list-item{
    background: #1890ff;
    border-radius: 5px;
}
.active-list-item h4,
.active-list-item .ant-list-item-meta-description{
  color: #fff;
}
.list-item{
  cursor: pointer;
}
.ant-descriptions-item-label{
  font-weight: 700 !important;
}
.list-arrow{
  display: none;
  color: #fff;
  font-size: 22px;
  padding-right: 10px;
}
.active-list-item .list-arrow{
  display: block;
}
.offers-container .action-buttons{
  position: relative;
  top: 20px;
  float: right ;
}
.offers-container .action-buttons .ant-btn{
  margin-right: 20px;
}
.offers-container .offer-status{
  text-transform: uppercase;
}
.offers-container .requests-checkbox{
  padding-left: 25px;
}

@media only screen and (max-width: 600px){
  div.requests-container div.list-content{
    min-height: 0;
  }
}
.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      -webkit-animation: App-logo-spin infinite 20s linear;
              animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @-webkit-keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  /**  CSS for Toasts     **/
  .ant-notification-notice.message-toast{
    background-color: #1890ff;
    color: white;
  }
  .ant-notification-notice{
    background-color: #001529;
    color: #fff;
  }
.ant-notification-notice-message{
  color: #fff;
  padding-bottom: 5px;
  width: 95%;

  font-weight: 100;
}
.message-toast .ant-notification-notice-message{
  display: none;
}
.message-toast .ant-notification-notice-description{
  width: 90%;
}
a.ant-notification-notice-close{
  color: #fff;
}


.error-toast{
  background-color: rgb(19, 18, 18);
}
.error-toast .ant-notification-notice-message{
  color: #fff;
  font-weight: 100;
}
.error-toast .ant-notification-notice-close i{
  top: 19px;
  color: #fff;
}

.title-toast{
  background-color: #33327b;
}
.success-btn{
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.success-btn:hover{
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}
.ant-spin-nested-loading{
  position: inherit;
}
button i.anticon{
  padding-right: 5px;
}
button.ant-btn-circle i{
  padding: 0;
  margin-bottom: 3px;
  /* vertical-align: unset; */
}

.left-btn{
  position: relative;
  float: left;
  left: 0;
}

.right-btn{
  position: relative;
  float: right;
  right: 0;
}

.full-btn{
  position: relative;
  width: 100%;
  margin: 20px 0 20px 0;
}

/* body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

body::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
					   left bottom,
					   left top,
					   color-stop(0.44, rgb(122,153,217)),
					   color-stop(0.72, rgb(73,125,189)),
					   color-stop(0.86, rgb(28,58,148)));
} */

/* @media only screen and (max-width: 2000px){
  .main-container .gradient-layer .form-container .form{
    right: 50px;
    width: 80%;
    top: 160px;
  }
} */

/* @media only screen and (max-width: 1700px){
  .main-container .gradient-layer .form-container .form{
    right: 50px;
    width: 80%;
  }
}

@media only screen and (max-width: 1620px){
  .main-container .gradient-layer .form-container .form{
    right: 50px;
    width: 80%;
  }
  .main-container .gradient-layer .form-container {
    top: 120px;
  } 
}

@media only screen and (max-width: 1500px){
  .main-container .gradient-layer .form-container .form{
    right: 50px;
    width: 90%;
    top: 180px;
  }
}

@media only screen and (max-width: 1400px){
  .main-container .gradient-layer .form-container .form{
    right: 50px;
    width: 94%;
    top: 120px;
  }
  .main-container .gradient-layer .header{
    height: 220px;
  }
}

@media only screen and (max-width: 1300px) and (max-height: 1050px){
  .main-container .gradient-layer .form-container .form{
    right: 50px;
    width: 100%;
    top: 230px;
  }
  .main-container .gradient-layer .header{
    height: 310px;
  }
}

@media only screen and (max-width: 1300px) and (max-height: 900px){
  .main-container .gradient-layer .form-container .form{
    right: 50px;
    width: 100%;
    top: 140px;
  }
  .main-container .gradient-layer .header{
    height: 210px;
  }
}

@media only screen and (max-width: 1200px){
  .main-container .gradient-layer .form-container .form{
    width: 70%;
    right: 0px;
  }
  .main-container .gradient-layer .text-container{
    width: 90%;
  }
}
@media only screen and (max-width: 1050px){
  .main-container .gradient-layer .form-container .form{
    width: 80%;
    right: 0px;
  }
  .main-container .gradient-layer .text-container{
    width: 90%;
  }
}

@media only screen and (max-width: 800px) {
  .gradient-layer{
    overflow-y: auto;
  }
 .main-container .gradient-layer .header{
   height: 150px;
 }
 .main-container .gradient-layer .header .logo{
   width: 40%;
   margin: 0 auto;
 }
 .main-container .gradient-layer .header .logo img{
   margin-top: 50px;
 }
 .main-container .gradient-layer .text-container{
   padding: 25px;
   width: 95%;
   background-color: rgba(255, 255, 255, 0.5); 
   margin: 0 auto;
   margin-top: 40px;
 }
 
 .main-container .gradient-layer .text-container p,
 .main-container .gradient-layer .text-container h1{
    color: #000;
 }
 .main-container .gradient-layer .text-container button.contact-button{
   top: 0px;
 }
 .main-container .gradient-layer .form-container .form .ant-radio-group .ant-radio-button-wrapper{
   width: 275px;
 }

 .main-container .gradient-layer .form-container .contact-button{
    float: right;
    left: 130px;
    top: 15px;
 }
 .contact-drawer .ant-drawer-content-wrapper{
   width: 50% !important;
 }
 .main-container .gradient-layer .form-container .form{
   right: 0;
   top: 100px;
   width: 90%;
   margin-bottom: 125px;
  }
}

@media only screen and (max-width: 600px) {
  .gradient-layer{
    overflow-x: hidden;
  }
  .main-container .gradient-layer .text-container{
    width: 94%;
  }
  .main-container .gradient-layer .header{
    height: 100px;
  }
  .main-container .gradient-layer .header .logo{
    width: 100%;
    padding: 50px;
    padding-top: 0px;
  }
  .main-container .gradient-layer .header .logo img{
    margin-top: 20px;
  }
  .main-container .gradient-layer .text-container h1{
    text-align: center;
  }
  .main-container .gradient-layer .text-container button.contact-button i {
    display: none;
    
  }
  .main-container .gradient-layer .text-container button.contact-button,
  .main-container .gradient-layer .form-container .form{
    width: 100%;
    left: 0;
  }
  .main-container .gradient-layer .form-container .form .ant-radio-group .ant-radio-button-wrapper{
    width: 150px;
  }
  .main-container .gradient-layer .form-container .contact-button{
    display: none;
  }
  .main-container .gradient-layer .form-container .contact-mobile-button{
    display: block;
    width: 82%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 400px) {
  .gradient-layer{
    overflow-x: hidden;
  }
  .main-container .gradient-layer .text-container{
    width: 94%;
  }
  .main-container .gradient-layer .header{
    height: 100px;
  }
  .main-container .gradient-layer .header .logo{
    width: 100%;
    padding: 50px;
    padding-top: 0px;
  }
  .main-container .gradient-layer .header .logo img{
    margin-top: 20px;
  }
  .main-container .gradient-layer .text-container h1{
    text-align: center;
  }
  .main-container .gradient-layer .text-container button.contact-button i {
    display: none;
    
  }
  .main-container .gradient-layer .text-container button.contact-button,
  .main-container .gradient-layer .form-container .form{
    width: 100%;
    left: 0;
  }
  .main-container .gradient-layer .form-container .form .ant-radio-group .ant-radio-button-wrapper{
    width: 120px;
  }
  .main-container .gradient-layer .form-container .form .ant-radio-group .ant-radio-button-wrapper i{
    display: none;
  }
  .main-container .gradient-layer .form-container .contact-button{
    display: none;
  }

  .main-container .gradient-layer .form-container .form .signup-button{
    width: 115px;
  }
  
  .main-container .gradient-layer .form-container .contact-mobile-button{
    display: block;
    width: 82%;
    margin: 0 auto;
  }
}

@media only screen and (min-height: 1000px) and (min-width: 1024px){
  .main-container .gradient-layer .form-container .form{
    top: 550px; 
  }
} */

